<template>
  <!-- 个人中心页面 -->
  <div>
    <div class="main">
      <!-- 个人中心左侧导航-->
      <div class="left">
        <div class="left_header">
          <div class="left_header_img">
            <img
              :src="
                personImgUrl && personImgUrl !== ''
                  ? personImgUrl
                  : defaulHeader
              "
              alt=""
            />
          </div>
          <span style="width: 100px">{{ realname }}</span>
          <p class="left_header-study-time">
            {{ class_time != "" ? class_time : 0 }}课时&nbsp;
          </p>
        </div>
        <!-- 头像下边的分割线 -->
        <div class="split_line"></div>
        <!-- 我的课程导航 -->
        <span class="left_item left_item_one" @click="Mycourse">
          <p
            class="list"
            :class="mycourse ? 'write' : 'nowrite'"
            :style="{ color: mycourse == true ? '#2BADEF' : 'black' }"
          >
            我的课程
          </p>
        </span>
        <!-- 我的收藏导航 -->
        <span class="left_item" @click="Mycollect">
          <p
            class="list"
            :class="mycollect ? 'write' : 'nowrite'"
            :style="{ color: mycollect == true ? '#2BADEF' : 'black' }"
          >
            我的收藏
          </p>
        </span>
        <span class="left_item" @click="MyVipClick">
          <p
            class="list"
            :class="myVip ? 'write' : 'nowrite'"
            :style="{ color: myVip == true ? '#2BADEF' : 'black' }"
          >
            我的会员
          </p>
        </span>
        <!-- 个人设置导航 -->
        <span class="left_item" @click="Settings">
          <p
            class="list"
            :class="settings ? 'write' : 'nowrite'"
            :style="{ color: settings == true ? '#2BADEF' : 'black' }"
          >
            个人设置
          </p>
        </span>
      </div>
      <!-- 导航对应的模块 -->
      <div class="right">
        <!-- 我的课程模块 -->
        <div class="mycourse" v-if="mycourse">
          <div style="display: flex; flex-wrap: wrap; margin-bottom: 26px">
            <div
              @click="
                $router.push(`/courdetails?cid=${item.id}&ct=${item.title}`)
              "
              class="course_item"
              v-for="item in mycourseList"
              :key="item.id"
            >
              <img class="course" :src="item.cover_img" alt="" />
              <p class="text_top">{{ item.title }}</p>
            </div>
          </div>
          <el-empty
            :image="require('../assets/images/no-data.png')"
            style="margin: 0 auto"
            v-show="courseEmpty"
          >
            <span slot="description"></span>
          </el-empty>
        </div>

        <!-- 底部分页器 -->
        <div class="pager" v-if="coursePaper">
          <el-pagination
            background
            style="text-align: center; margin-top: 0px; margin-bottom: 26px"
            layout="prev, pager, next"
            :total="course.count"
            class="page"
            :page-size="Number(course.page_size)"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChangeCourse"
            v-show="course.count > 12"
          >
          </el-pagination>
        </div>

        <!-- 我的收藏模块-->
        <div class="mycollect" v-if="mycollect">
          <div
            @click="
              $router.push(`/courdetails?cid=${item.id}&ct=${item.title}`)
            "
            class="collect_item"
            v-for="item in mycollectList"
            :key="item.id"
          >
            <div class="collect_img">
              <img class="collect" :src="item.cover_img" alt="" />
            </div>
            <div class="text_collect">
              <span class="text_top">{{ item.title }}</span>
              <img
                class="collect"
                @click.stop="cancelCollect(item)"
                src="../assets/images/collect.png"
                alt=""
              />
            </div>
          </div>
          <el-empty
            :image="require('../assets/images/no-data.png')"
            style="margin: 0 auto"
            v-show="colEmpty"
          >
            <span slot="description"></span>
          </el-empty>
        </div>
        <!-- 底部分页器 -->
        <div class="pager" v-if="collectPaper">
          <el-pagination
            background
            style="text-align: center; margin-top: 0px; margin-bottom: 26px"
            layout="prev, pager, next"
            :total="collect.count"
            class="page"
            :page-size="Number(collect.page_size)"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChangeColl"
            v-show="collect.count > 12"
          >
          </el-pagination>
        </div>
        <div v-if="myVip" class="my-vip">
          <VipList />
        </div>
        <!-- 个人设置模块 -->
        <div class="settings" v-if="settings">
          <div class="set">
            <div class="setheader">
              <span class="header_write">头像：</span>
              <div class="header_img">
                <label class="change_avatar" for="avatar">
                  <img
                    class="change_avatar_img"
                    :src="
                      personImgUrl && personImgUrl !== ''
                        ? personImgUrl
                        : personalForm.head_img != ''
                        ? personalForm.head_img
                        : defaulHeader
                    "
                    alt=""
                  />
                </label>
                <input
                  type="file"
                  id="avatar"
                  hidden
                  @change="improveInfoImg"
                />
              </div>
            </div>
            <div class="realname">
              <span class="realname_left">昵称：</span>
              <el-input
                v-model="realname"
                class="realname_middle"
                type="text"
                @blur="changeName"
              ></el-input>
            </div>
            <el-button class="logout" style="background: #fff">
              <el-popover placement="bottom" width="160" v-model="popShow">
                <p>确定退出登录吗？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="popShow = false"
                    >取消</el-button
                  >
                  <el-button type="primary" size="mini" @click="logout"
                    >确定</el-button
                  >
                </div>
                <el-button
                  type="text"
                  style="
                    margin-left: -30px;
                    background: #8bb9ff;
                    border-radius: 50px;
                    margin-bottom: 80px;
                  "
                  class="del-slot-btn user-list-operate"
                  slot="reference"
                >
                  退出登录
                </el-button>
              </el-popover>
            </el-button>
          </div>
        </div>

        <!-- 灰色遮罩 -->
        <div class="popup" v-if="popupMask">
          <!--修改单位弹框 -->
          <div class="updateCompany" v-if="updateCompany">
            <div class="upCom_top">
              <span class="upCom_text">修改企业名称</span>
              <img
                class="close_btn"
                @click="close_btn"
                src="../assets/images/close_btn.png"
                alt=""
              />
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">修改企业名称</span>
              <el-col :span="48">
                <el-input
                  placeholder="请输入完整的企业名称"
                  v-model="upCom_input"
                  style="width: 80%"
                />
              </el-col>
            </div>
            <div class="upCom_bottom">
              <button class="cancel" @click="close_btn">取消</button>
              <button class="sure" @click="updateCom">确定</button>
            </div>
          </div>
          <!-- 修改手机号弹窗 -->
          <div class="updatePhone" v-if="updatePhone">
            <div class="upCom_top">
              <span class="upCom_text">修改手机号</span>
              <img
                class="close_btn"
                @click="close_btn"
                src="../assets/images/close_btn.png"
                alt=""
              />
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">手机号</span>
              <el-input
                class="upCom_input_phone"
                v-model="setphone"
                placeholder="请输入旧手机号"
              ></el-input>
              <el-button
                class="getCode"
                :disabled="btnDisabled"
                @click="getCode"
                >获取验证码
                {{ phoneBtnCount == 60 ? "" : phoneBtnCount + "s" }}</el-button
              >
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">验证码</span>
              <el-input
                class="upCom_input_code"
                v-model="phoneForm.code"
                placeholder="请输入短信验验证码"
              ></el-input>
            </div>
            <div class="upCom_middle">
              <span class="upCome_name">新手机</span>
              <el-input
                class="upCome_input_new"
                v-model="phoneForm.new_mobile"
                placeholder="请输入新手机号"
              ></el-input>
            </div>
            <div class="upCom_bottom">
              <button class="cancel" @click="close_btn">取消</button>
              <button class="sure" @click="updatePho">确定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaulHeader from "../assets/images/default_myhead.png";
import "viewerjs/dist/viewer.css";
import { directive as viewer } from "v-viewer";
import VipList from "../components/VipcardList.vue";
export default {
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  data() {
    return {
      tabPosition: "left", //框架自带
      course: {}, //课程data
      mycourse: true, //我的课程-模块
      mycourseList: [], //我的课程
      coursePaper: true, //我的课程-分页
      collect: {}, //收藏data
      mycollect: false, //我的收藏模块
      mycollectList: [], //我的收藏
      collectPaper: false, //我的收藏-分页
      myVip: false,
      hiss: {}, //记录data
      history: false, //考试记录模块
      historyList: [], //考试记录
      cert: {}, //证书data
      certList: [],
      settings: false, //个人设置模块
      defaulHeader: defaulHeader, //默认显示头像路径
      personImgUrl: "", //上传时显示的路
      updateCompany: false, //修改单位弹框
      upCom_input: "", //修改-单位-v-model
      popupMask: false, //灰色遮罩
      updatePhone: false, //修改手机号弹框
      phoneBtnCount: 60, //验证码的60秒
      btnDisabled: false, //控制验证码是否能点击
      phoneForm: {
        //修改手机号表单v-model
        mobile: "",
        code: "",
        new_mobile: "",
      },
      personalForm: {
        head_img: "",
      },
      viewerOptions: {
        movable: false,
        title: false,
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          prev: 4,
          play: false,
          next: 4,
          rotateLeft: 4,
          rotateRight: 4,
          flipHorizontal: 4,
          flipVertical: 4,
        },
      },
      realname: "",
      company_name: "",
      setphone: "",
      popShow: false,
      state1: "",
      state2: "",
      companyList: [],
      hanSelect: null, //下边的选项
      currentPage: 1, //默认显示第一页
      colEmpty: false,
      courseEmpty: false,
      study_time: "",
      class_time: "",

      displayValueH: "",
      displayValueC: "",
      displayValueT: "",

      certInfo: {},
    };
  },
  mounted() {
    this.getmyCourse();
    this.getCenterInfo();
  },
  methods: {
    async getCenterInfo() {
      let data = await this.axios.get("/course/personal/personal_center");
      console.log(data);
      this.personImgUrl = "https://mobile.xlteacher.cn" + data.data.head_img;
      this.class_time = data.data.study_time;
      this.realname = data.data.name;
    },
    toResult(row) {
      this.$router.push(`/results?pid=${row.id}&etitle=${row.name}`);
    },
    Mycourse() {
      this.mycourse = true;
      this.mycollect = false;
      this.history = false;
      this.settings = false;
      this.myVip = false;
      this.getmyCourse();
      this.coursePaper = true;
      this.collectPaper = false;
    },
    Mycollect() {
      this.mycollect = true;
      this.mycourse = false;
      this.history = false;
      this.settings = false;
      this.getmyCollection();
      this.myVip = false;
      this.collectPaper = true;
      this.coursePaper = false;

      this.courseEmpty = false;
    },

    Settings() {
      this.settings = true;
      this.mycourse = false;
      this.mycollect = false;
      this.history = false;

      this.coursePaper = false;
      this.collectPaper = false;
      this.courseEmpty = false;
      this.myVip = false;
    },
    MyVipClick() {
      this.myVip = true;
      this.settings = false;
      this.mycourse = false;
      this.mycollect = false;
      this.history = false;

      this.coursePaper = false;
      this.collectPaper = false;
      this.courseEmpty = false;
    },

    //获取我的课程请求
    getmyCourse() {
      let fd = new FormData();
      fd.append("page_size", 12);
      this.axios.get(`/coursepc/index/my_course`).then((res) => {
        this.course = res.data;
        this.mycourseList = res.data.list;
        if (res.data.count == 0) {
          this.courseEmpty = true;
        }
      });
    },
    //我的课程更改页码
    handleCurrentChangeCourse(page) {
      this.currentPage = page;
      this.axios.get(`/coursepc/index/my_course?page=${page}`).then((res) => {
        this.course = res.data;
        this.mycourseList = res.data.list;
      });
    },

    //获取我的收藏请求
    getmyCollection(page = 1) {
      this.axios
        .get(`/coursepc/index/my_collection?page=${page}`)
        .then((res) => {
          console.log(res);

          this.collect = res.data;
          this.mycollectList = res.data.list;
          if (res.data.count == 0) {
            this.colEmpty = true;
          }
        });
    },
    //我的收藏更改页码
    handleCurrentChangeColl(page) {
      this.currentPage = page;
      this.getmyCollection(page);
    },
    //取消收藏请求
    cancelCollect(item) {
      let fd = new FormData();
      fd.append("id", item.id);
      fd.append("is_collection", 0);
      this.axios.post("/coursepc/index/change_collection", fd).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("取消成功");
          this.getmyCollection();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //点关闭叉号时清除表单
    close_btn() {
      this.updateCompany = false;
      this.popupMask = false;
      this.upCom_input = "";
      // this.phoneForm.mobile="";
      this.phoneForm.code = "";
      this.phoneForm.new_mobile = "";
    },
    //修改头像的上传文件
    async improveInfoImg(e) {
      let file = e.target.files[0];
      let fr = new FileReader();
      fr.readAsDataURL(e.target.files[0]);
      fr.onload = () => {
        this.personImgUrl = fr.result;
      };
      let fd = new FormData();
      fd.append("name", "");
      fd.append("head_img", file);
      let data = await this.axios.post("/coursepc/personal/upd_perinfo", fd);
      if (data.data.code === 0) {
        this.$message.success(data.data.msg);
      } else {
        this.$message.error(data.data.msg);
      }
    },
    async changeName() {
      let fd = new FormData();
      fd.append("name", this.realname);
      fd.append("head_img", "");
      let data = await this.axios.post("/coursepc/personal/upd_perinfo", fd);
      if (data.data.code === 0) {
        this.$message.success(data.data.msg);
      } else {
        this.$message.error(data.data.msg);
      }
    },

    //确定退出登录
    logout() {
      //this.popShow=true;
      this.axios.post("/course/personal/login_out").then((res) => {
        this.$store.dispatch("REMOVE_CLOUD_PC");
        this.$router.push("/");
      });
    },
    // 秒转分秒
    formatSeconds(time) {
      console.log(time);
      let result = parseInt(time);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);

      return m + ":" + s;
    },
  },
  components: { VipList },
};
</script>

<style lang="scss">
body {
  background: #f8f8f8;
}
.realname {
  .el-input__inner {
    height: 30px;
  }
}
.el-tabs__active-bar {
  background: none;
}
.right {
  /* el-table表格去掉横线 */
  .el-table__row > td {
    border: none;
  }
  .el-table::before {
    height: 0px;
  }
  .logout {
    .el-button--text {
      width: 150px;
      height: 50px;
      font-size: 15px;
      color: #fff;
      padding-bottom: 12px;
      border-radius: 0;
    }
  }
}

.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
.upCom_middle {
  .el-input__inner {
    width: 150%;
  }
}
@media screen and (max-width: 1555px) and (min-width: 1380px) {
  .viewer-transition {
    padding-left: 200px;
    padding-top: 60px;
  }
}
@media screen and (max-width: 1380px) {
  .viewer-transition {
    padding-left: 320px;
    padding-top: 80px;
  }
}
</style>

<style lang="scss" scoped>
body {
  width: 100%;
  min-height: calc(100vh - 70px);
  position: relative;
}
.main {
  width: 60.3%;
  height: 100%;
  //background: rgb(117, 117, 116);
  // background: #f8f8f8;
  margin: 0 auto;
  padding-top: 44px;
  display: flex;
  //左侧导航
  .left {
    width: 20%;
    height: 41%;
    // background: rgb(223, 223, 180);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    padding-bottom: 50px;
    .left_header {
      // width: 74px;
      // background: lightsteelblue;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      margin-top: -20px;
      text-align: center;
      margin-bottom: 30px;
      border: 0px;
      .left_header_img {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        border: 0px;

        img {
          width: 74px;
          height: 74px;
          border-radius: 50%;
          border: 0px;
          overflow: hidden;
          object-fit: cover; //替换图片的时候保持比例
          // background: url(../assets/images/default_header.png) center center
          // no-repeat;
          background-size: 100px 100px;
        }
        img:not([src]) {
          opacity: 0;
        }
      }
      span {
        margin-top: 15px;
      }
      .left_header-study-time {
        margin: 0;
        margin-top: 10px;
        font-size: 14px;
      }
    }
    .split_line {
      width: 100%;
      height: 1px;
      background: rgb(228, 230, 230);
      margin-bottom: 10px;
    }
    .left_item {
      width: 80px;
      height: 60px;
      text-align: center;
      margin-top: -5px;
      font-size: 16px;
      margin: 0 auto;
      .list {
        cursor: pointer;
        padding-bottom: 8px;
      }
      .write {
        border-bottom: 1.5px solid #2badef;
      }
      .nowrite {
        border-bottom: 1.5px solid #fff;
      }
    }
  }
  //右侧切换模块
  .right {
    width: 100%;
    height: 100%;
    // background: darksalmon;
    background: #ffffff;
    margin-bottom: 20px;
    .mycourse {
      width: 100%;
      height: 100%;
      // background: rgb(192, 185, 156);
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .course_item {
        width: 220px;
        height: 190px;
        //  background: darksalmon;
        margin-left: 12px;
        margin-top: 12px;
        .text_top {
          font-size: 16px;
          color: #333333;
          margin-top: 9px;
          margin-bottom: 20px;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .text_bottom {
          color: #666666;
          font-size: 14px;
          margin-top: -15px;
        }
        .course {
          width: 225px;
          height: 134px;
        }
      }

      .pager {
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .mycourse-year {
        width: 100%;
        height: 100%;
        padding-top: 26px;
        padding-bottom: 14px;
        //background: pink;
        overflow: hidden;
        .mycourse-year-box {
          width: 50%;
          height: 100%;
          margin: 0 auto;
          //background: greenyellow;
          .mycourse-year-picker {
            width: 300px;
            margin-left: 12px;
            // margin-top: 10px;
          }
          .reset-course {
            margin-left: 20px;
            // margin-top: 10px;
          }
        }
      }
    }
    .mycollect {
      width: 100%;
      height: 100%;
      //background: rgb(192, 185, 156);
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      margin-bottom: 25px;
      .collect_item {
        .collect_img {
          width: 225px;
          height: 134px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        width: 225px;
        height: 170px;
        // background: darksalmon;
        margin-left: 10.9px;
        margin-top: 26px;
        .text_collect {
          display: flex;
          justify-content: space-between;
          .collect {
            width: 17px;
            height: 17px;
            vertical-align: text-bottom;
            margin-left: 5px;
            margin-top: 10px;
            cursor: pointer;
          }
          .text_top {
            font-size: 16px;
            //  color: #333333;
            margin-top: 8px;
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .text_bottom {
          //  color: #666666;
          font-size: 14px;
          margin-top: -15px;
        }
      }
      .pager {
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    .history {
      //width: 100%;
      height: 100%;
      font-size: 14px;
      .pager {
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .history-year {
        //background: grey;
        margin-top: 16px;
        margin-bottom: 26px;
        .history-year-box {
          width: 50%;
          height: 100%;
          //background:lemonchiffon;
          margin: 0 auto;
          .history-year-picker {
            width: 300px;
            margin-left: 12px;
            margin-top: 10px;
          }
          .reset-course {
            margin-left: 20px;
            margin-top: 10px;
          }
        }
      }
    }
    .mycertificate {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      padding-top: 26px;
      margin-bottom: 23px;
      .cert_item {
        width: 158px;
        // height: 220px;
        margin-left: 20px;
        margin-right: 12px;
        font-size: 14px;
        text-align: center;
        // background: lemonchiffon;
        //  margin-bottom: 40px;
        margin-top: 10px;
        .cert_item_img {
          margin-bottom: -12px;
          width: 100%;
          cursor: pointer;
        }
        p {
          margin: 0;
          margin-top: 15px;
        }
      }
      .pager {
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .mycert-year {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background: grey;
        // margin-top: -5px;
        margin-bottom: 6px;
        // padding: 0 15px;
        padding-right: 20px;
        .cert-year-box {
          // width: 50%;
          .cert-year-picker {
            width: 300px;
            margin-left: 12px;
            //  margin-top: 10px;
          }
          .reset-course {
            margin-left: 20px;
            // margin-top: 10px;
          }
        }
      }
    }
    .my-vip {
      min-height: 450px;
      padding: 15px;
      padding-bottom: 30px;
    }
  }
  //个人设置
  .settings {
    width: 100%;
    height: 100%;
    .set {
      width: 100%;
      height: 100%;
      .setheader {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        .header_write {
          margin-left: 45px;
        }
        .header_img {
          margin-left: 40px;
          border: 0px;
          .change_avatar {
            display: block;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            text-align: center;
            margin: 0 auto;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            .change_avatar_img {
              width: 100%;
              height: 100%;
              border: 0px solid transparent;
              object-fit: cover; //替换图片的时候保持比例
            }
            img:not([src]) {
              opacity: 0;
            }
            &:hover {
              &::after {
                content: "编辑头像";
                font-size: 15px;
                display: block;
                width: 100%;
                height: 40px;
                line-height: 32px;
                color: #fff;
                border-radius: 0 0 100px 100px;
                background-color: rgba($color: rgb(39, 37, 37), $alpha: 0.4);
                position: absolute;
                bottom: 0;
              }
            }
          }
        }
      }
      .realname {
        height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        .realname_left {
          font-size: 16px;
          margin-left: 46px;
        }
        .realname_middle {
          margin-top: -5px;
          width: 180px;
          margin-left: 30px;
          outline: none;
          border: 1px solid #f0f0f0;
          border-radius: 3px;
          height: 22px;
        }
        .realname_right {
          margin-left: 30px;
          color: #66c3f1;
          font-size: 12px;
          margin-top: 2px;
        }
      }
      .save_btn {
        margin-top: 30px;
        margin-bottom: 50px;
        margin-left: 20%;
      }
      .setcompany {
        height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        .company_left {
          margin-left: 46px;
          font-size: 16px;
        }
        .company_middle {
          border: 1px solid transparent;
          width: 200px;
          font-size: 14px;
          color: #666666;
          margin-left: 30px;
          margin-top: 2px;
          padding: 8px;
        }
        .company_right {
          margin-left: 23px;
          color: red;
          font-size: 14px;
          cursor: pointer;
          margin-top: -1px;
        }
      }
      .setphone {
        height: 70px;
        width: 100%;
        display: flex;
        align-items: center;
        .phone_left {
          margin-left: 48px;
        }
        .phone_middle {
          border: 1px solid transparent;
          width: 100px;
          font-size: 14px;
          color: #666666;
          margin-left: 30px;
          padding: 8px;
        }
        .phone_right {
          font-size: 14px;
          margin-left: 23px;
          color: red;
          cursor: pointer;
          margin-top: -3px;
        }
      }
      .logout {
        margin-top: 10px;
        margin-left: 170px;
        border: 1px solid transparent;
      }
    }
  }
  .popup {
    width: 100%;
    height: 100%;
    background-color: rgba($color: rgb(66, 65, 65), $alpha: 0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    // 修改单位样式
    .updateCompany {
      width: 500px;
      height: 300px;
      background: #fff;
      border: 1px solid rgb(235, 231, 231);
      position: absolute;
      left: 37%;
      top: 30%;
      z-index: 99px;
      border-radius: 10px;
      .upCom_top {
        display: flex;
        justify-content: space-between;
        .upCom_text {
          margin-top: 15px;
          margin-left: 20px;
          font-size: 18px;
        }
        .close_btn {
          width: 40px;
          height: 40px;
          margin-top: 10px;
          margin-right: 20px;
        }
      }
      .upCom_middle {
        display: flex;
        margin-top: 60px;
        .upCome_name {
          margin-left: 50px;
          font-size: 18px;
          margin-top: 7px;
          margin-right: 10px;
        }
        .el-input {
          width: 280px;
        }
      }
      .upCom_bottom {
        margin-top: 80px;
        margin-left: 130px;

        .sure {
          width: 80px;
          height: 40px;
          margin-left: 100px;
          background: #83b1fc;
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
        .cancel {
          width: 80px;
          height: 40px;
          background: rgb(216, 216, 221);
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
      }
    }
    //修改手机样式
    .updatePhone {
      width: 500px;
      height: 400px;
      background: #fff;
      border: 1px solid rgb(235, 231, 231);
      position: absolute;
      left: 37%;
      top: 25%;
      z-index: 99px;
      border-radius: 10px;
      .upCom_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        .upCom_text {
          margin-top: 15px;
          margin-left: 20px;
          font-size: 18px;
        }
        .close_btn {
          width: 40px;
          height: 40px;
          margin-top: 10px;
          margin-right: 20px;
        }
      }
      .upCom_middle {
        display: flex;
        margin-top: 25px;
        .upCome_name {
          margin-left: 50px;
          font-size: 16px;
          margin-top: 7px;
          margin-right: 10px;
        }
        .upCom_input_phone {
          width: 115px;
        }
        .getCode {
          background: #83b1fc;
          color: #fff;
          border-radius: 2px;
          width: 122px;
          height: 40px;
          margin-left: 70px;
          text-align: center;
          padding-top: 10px;
        }
        .upCom_input_code {
          width: 205px;
        }
        .upCome_input_new {
          width: 205px;
        }
      }
      .upCom_bottom {
        margin-top: 50px;
        margin-left: 130px;

        .sure {
          width: 80px;
          height: 40px;
          margin-left: 100px;
          background: #83b1fc;
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
        .cancel {
          width: 80px;
          height: 40px;
          background: rgb(216, 216, 221);
          border: 0;
          color: #fff;
          border-radius: 2px;
        }
      }
    }
  }
}
.c-b {
  width: 0;
  height: 0;
  overflow: hidden;
  .certificate {
    width: 1080px;
    height: 1524px;
    background: url("../assets/images/cert.jpg") no-repeat;
    background-size: cover;
    position: relative;
    top: 100%;
    left: 0;
    // z-index: -99;
    span {
      font-size: 38px;
      // font-weight: 400;
      font-family: "楷体";
      position: absolute;
      color: #3a3a3a;
    }
    .cert-name {
      top: 37.1%;
      left: 19%;
    }
    .cert-stime {
      top: 37.1%;
      left: 42.5%;
      // font-size: 32px;
    }
    .cert-etime {
      top: 37.1%;
      left: 70%;
      // font-size: 32px;
    }
    .cert-course-time {
      top: 45.2%;
      right: 30%;
    }
    .cert-identifier {
      bottom: 22.3%;
      left: 30.5%;
      font-size: 26px;
    }
    .cert-ftime {
      left: 30.5%;
      bottom: 17.7%;
      font-size: 26px;
    }
    .cert-cname {
      right: 13%;
      bottom: 12%;
    }
  }
}
</style>
