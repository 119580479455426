<template>
  <div class="card">
    <!-- 会员套餐 -->
    <div class="vip_package">
      <b>会员套餐</b>
      <div class="vip_package_box">
        <div
          class="box_item"
          v-for="(card, key) in cardList"
          :key="card.id"
          @click="changeStatus(card, key)"
        >
          <input
            :checked="card.button == 0 || card.level == 2"
            type="radio"
            :id="card.vip_id"
            hidden
            name="vip_name"
          />
          <label :for="card.vip_id">
            <span class="vip_days">365天</span>
            <b class="vip_price">
              <i>¥</i>
              {{ card.price }}
            </b>
            <span class="vip_name">{{ card.vip_name }}</span>
          </label>
        </div>
      </div>
      <div class="vip_introduce">
        <span
          v-for="(card, key) in cardList"
          :key="card.id"
          v-show="currentVip == key"
        >
          <b>{{ card.vip_name }}：</b>
          {{ card.info }}</span
        >
      </div>
      <button
        @click="openVip(activeData)"
        class="vip_btn vip_enbtn"
        v-if="activeData.button == 1"
      >
        立即开通
      </button>
      <button class="vip_btn vip_disbtn" disabled v-if="activeData.button != 1">
        已开通
      </button>
    </div>
    <p class="expire_time" v-if="myVip">过期时间: {{ myVip.expire_time }}</p>
  </div>
</template>

<script>
// import { Carousel, Navigation, Slide } from "vue3-carousel";

// import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      cardList: [],
      swiperIndex: "",
      // 选中数据
      activeData: {},
      cardLen: 0,
      currentVip: 0,
      myVip: null,
      enDIs: 1,
    };
  },

  // components: { Carousel, Slide, Navigation },

  computed: {},

  mounted() {
    this.axios.post("/course/personal/viplist").then((res) => {
      this.cardList = res.data;
      this.activeData = res.data[0];
      console.log(this.activeData);
    });
    if (this.$route.query.vid) {
      let fd = new FormData();
      fd.append("id", this.$route.query.vid);
      this.axios.post("/course/personal/myvip", fd).then((res) => {
        this.myVip = res.data;
      });
    }
  },
  methods: {
    // 更改轮播图切换下方会员介绍
    changeStatus(card, key) {
      this.currentVip = key;
      this.activeData = card;
    },
    openVip(vip) {
      this.$router.push(`/order?oids=${vip.vip_id}&type=2`);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  padding-top: 1px;
  overflow: hidden;
  margin-top: 16px;
  // 蛋型背景图
  .card_item {
    width: 80%;
    height: 200px;
    margin: 0 auto;
    background: url(../assets/images/vip_card_bg.png) no-repeat;
    position: relative;
    border-radius: 5px;
    p {
      position: absolute;
      margin: 0;
    }
    .card_item_title {
      top: 50px;
      left: 52px;
      font-size: 34px;
      color: #e0b45e;
      img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
    }
    .card_item_content {
      bottom: 20px;
      right: 52px;
      color: #f0d6bc;
      font-size: 24px;
    }
  }

  .welfare {
    margin-top: 35px;
    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          width: 60px;
          height: 60px;
          img {
            width: 100%;
          }
        }
        p {
          font-size: 14px;
          color: #02080f;
          margin-top: 23px;
          margin-bottom: 10px;
        }
        span {
          color: rgba($color: #02080f, $alpha: 0.6);
          font-size: 16px;
        }
      }
    }
  }
  // 轮不播
  .van-swipe-item {
    margin-top: 33px;
    text-align: center;
    .card_type {
      width: 640px;
      // height: 352px;
      margin: 100px auto 0;
    }
  }
  .card_open {
    position: relative;
    top: -80px;
    // left: 235px;
    left: 50%;
    transform: translateX(-50%);
    width: 274px;
    height: 107px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .rights {
    padding: 0 40px;
    text-align: center;
    b {
      font-size: 36px;
      color: #353535;
      position: relative;

      &::before,
      &::after {
        content: "";
        display: inline-block;
        width: 39px;
        height: 8px;
        vertical-align: middle;
        background: #3b3b39;
      }
      &::before {
        margin-right: 23px;
      }
      &::after {
        margin-left: 23px;
      }
    }
    p {
      text-align: left;
      line-height: 1.8;
      color: #3b3b39;
      font-size: 24px;
    }
  }
}
.vip_package {
  width: 690px;
  margin: 0 auto;
  margin-top: 40px;
  & > b {
    color: #333333;
    font-size: 20px;
  }
  .vip_package_box {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .box_item {
      position: relative;
      label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 200px;
        background: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;

        .vip_days {
          font-size: 28px;
          color: #9c9c9c;
        }
        .vip_price {
          font-size: 40px;
          margin: 20px 0;
          color: #894d1c;
          i {
            font-style: normal;
            font-size: 34px;
          }
        }
        .vip_name {
          font-size: 18px;
          color: #333333;
        }
      }
      input {
        position: absolute;
        top: 0;
        &:checked + label {
          border: 4px solid #ecba8c;
          border-radius: 17px;
          background: #fdf6f0;
          .vip_days {
            color: #894d1c;
          }
        }
      }
    }
  }
  .vip_introduce {
    margin-top: 30px;
    font-size: 24px;
    color: #3b3b39;
    line-height: 1.5;
  }
  .vip_btn {
    width: 689px;
    height: 88px;
    border-radius: 14px;
    border: 0;
    font-size: 28px;
    margin-top: 56px;
    cursor: pointer;
  }
  .vip_enbtn {
    background: linear-gradient(89deg, #fee4cd 0%, #f0c49f 100%);
    color: #894d1c;
  }
  .vip_disbtn {
    background: #eaeaea;
    color: #969595;
  }
}
.expire_time {
  width: 298px;
  text-align: center;
  margin: 0;
  font-size: 24px;
  color: #61615f;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
